import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General Guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
    </AnchorLinks>
    <h2>{`General Guidance`}</h2>
    <p>{`The ListBox control enables you to display a list of items to the user that the user can select by clicking. The ListBox also provides the MultiColumn property to enable the display of items in columns instead of a straight vertical list of items. With this, the control can display more visible items and the user no longer needs to scroll to an item.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Listbox allows users to select single selection in one single dropdown menu or in multiple levels dropdown menu`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      